@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-enter {
  opacity: 0;
  transform: translateY(10%);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
}

.table-enter-leave-demo-table .ant-table-thead > tr,
.table-enter-leave-demo-table .ant-table-tbody > tr {
  transition: none;
}

.ant-typography-ellipsis {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
}

.ant-layout-sider {
  &:hover {
    &::-webkit-scrollbar {
      display: block;
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #949494;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ant-menu-submenu-open {
  background-color: #e0eddf;
}

.ant-menu-submenu-selected {
  background-color: transparent;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-header {
  border: none;
  border-radius: 0;
}

/* Dropdown - scroll with more content */
.dropdown-scroll-style {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
/* Hiding scrollbar for Chrome, Safari and Opera */
#dropdown-scroll-style::-webkit-scrollbar {
  display: none;
}

.tableFooterToolbar .ant-popover-inner-content{
  padding: 0;
}

.ant-cascader-menus {
  max-width: 100vw;
  overflow: auto;
  padding-Bottom: 5px;

  .ant-cascader-menu {
    height: 400px;  
  }
}

.ant-switch.isOnSale.ant-switch-checked {
  background-color: #fcb103;
}
.ant-switch.isMember.ant-switch-checked {
  background-color: #CC0066;
}
.ant-switch.isPresale.ant-switch-checked {
  background-color: #73dbee;
}



@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

.redRow{
  background: #FF0000;
  &:hover {
    background-color: yellow;
  }
}


.tablePrintout {
  // Print out Form Value
  .ant-descriptions-header {
    margin: 0 !important;
    border-bottom: 1px solid #000 !important;
  }

  //Table header
  .ant-table-title {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-top: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
    border-right: 1px solid #000 !important;
  }

  // Order Goods Table
  page-break-inside: auto; 
  table {
    border-collapse: collapse !important;
  }  
  .ant-table-cell{
    padding: 0 !important;   
  }
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td{
    border: 1px solid black !important;
  }

  .ant-table-bordered .ant-table-footer{
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
    border-bottom: 1px solid black !important;
  }

  @media print {
    /* Override default print styles for tables */
    table {
      border-collapse: collapse !important;
    }  
    .ant-table-cell{
      padding: 0 !important;   
    }
    .ant-table-bordered .ant-table-thead > tr > th,
    .ant-table-bordered .ant-table-tbody > tr > td{
      border: 1px solid black !important;
    }
  
    .ant-table-bordered .ant-table-footer{
      border-left: 1px solid black !important;
      border-right: 1px solid black !important;
      border-bottom: 1px solid black !important;
    }
  }
}


.excelPrintout  {
  @media print {
    table{
      width: auto!important;
      table-layout: fixed!important;
    }
    .ant-table-cell {
    }
  }
}  



.tableDriverPrintout {
  .ant-table-tbody > tr > td{
    padding: 0 !important;
    height: 64px !important;
  }
  .ant-table-cell{
    border-bottom: solid 2px black !important;
    border-left: solid 2px black !important;
  }
  .ant-table{
    border-top: solid 2px black !important;
    border-right: solid 2px black !important; 
  }
}
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.ql-html-textContainer{
  max-height: 680px;
}

.ql-html-popupContainer{
  max-height: 700px;
}

.ql-editor {
  flex: auto;
  overflow-y: auto;
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}

// Table row-dragging
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

@primary-color: #e5832f;